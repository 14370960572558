@import '../../abstracts/variables.sass';

.quickOnboardingProgress {
  display: flex;
  align-items: center;

  &__step {
    display: flex;
    align-items: center;

    &[data-is-highlighted='true'] {
      .quickOnboardingProgress__step--number {
        background-color: #000000;
        &-content {
          color: var(--main-btn-text-color);
        }
      }

      .quickOnboardingProgress__step--title {
        color: var(--black-white-color);
      }

      .quickOnboardingProgress__step--line {
        background-color: var(--black-white-color);
      }
    }

    &--number {
      color: var(--black-white-color);
      transition: $normal-transition;
      cursor: pointer;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #71717a;
      margin-right: 1rem;
      @media only screen and (max-width: 400px) {
        margin-right: 0;
      }
    }

    &--title {
      cursor: pointer;
      transition: $normal-transition;
      font-size: 1.6rem;
      font-weight: 400;
      color: #71717a;
      @media only screen and (max-width: 400px) {
        display: none;
      }
    }

    &--line {
      transition: $normal-transition;
      flex-grow: 1;
      height: 2px;
      background-color: #71717a;
      margin: 0 1rem;
    }
  }
}
