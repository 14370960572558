@import '../abstracts/variables.sass';

$toggle-indicator-size: 2rem; // changing this number will resize the whole toggle
$track-height: $toggle-indicator-size + 0.6;
$track-width: $toggle-indicator-size * 2.5;
$highContrastModeSupport: solid 2px transparent;

$light: var(--sidebar-background-color);
$mid: $main-light-gray-font-color-2;
$dark: var(--main-btn-color);
$speed: 0.4s;

$track-border: $mid;
$track-background: $light;
$focus-ring: 0rem 0rem 0rem 0.1rem $dark;

// Toggle specific styles
.toggle {
  align-items: center;
  border-radius: 1rem;
  display: flex;
  font-weight: 700;
  margin-bottom: 1.6rem;

  &:last-of-type {
    margin: 0;
  }

  &-container {
    display: flex;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 1.2rem;
      color: var(--main-text-color);
      margin-bottom: 0rem;
      margin-top: 0.2rem;
      font-family: var(--main-font-secondary) !important;

      &[data-is-toggle='true'] {
        color: rgb(177, 177, 177);
      }
    }
  }
}

// Since we can't style the checkbox directly, we "hide" it so we can draw the toggle.
.toggle__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.1rem;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;

  // This style sets the focus ring. The ":not([disabled])" prevents the focus ring from creating a flash when the element is clicked.
  /*  &:not([disabled]):active + .toggle-track, */
  &:not([disabled]):checked + .toggle-track {
    border: 0.1rem solid #585858;
    box-shadow: $focus-ring;
  }

  &:disabled + .toggle-track {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.toggle-track {
  background: $track-background;
  border: 0.1rem solid $track-border;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  height: $track-height;
  margin-right: 1.2rem;
  position: relative;
  width: $track-width;
}

.toggle-indicator {
  align-items: center;
  background: $mid;
  border-radius: $toggle-indicator-size;
  bottom: 0.2rem;
  display: flex;
  height: $toggle-indicator-size;
  justify-content: center;
  left: 0.2rem;
  outline: $highContrastModeSupport;
  position: absolute;
  transition: $speed;
  width: $toggle-indicator-size;
}

// The check mark is optional
.checkMark {
  fill: #fff;
  height: $toggle-indicator-size - 0.4;
  width: $toggle-indicator-size - 0.4;
  opacity: 0;
  transition: opacity $speed ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
  background: $dark;
  transform: translateX($track-width - $track-height);

  .checkMark {
    opacity: 1;
    transition: opacity $speed ease-in-out;
  }
}

@media screen and (-ms-high-contrast: active) {
  .toggle-track {
    border-radius: 0;
  }
}
