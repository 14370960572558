@import '../../abstracts/variables.sass';

.quickOnboardingIntegrations {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    &--left {
      font-size: 2.4rem;
      font-weight: 700 !important;
      color: var(--main-text-color);
    }

    &--right {
      &-connection {
        cursor: pointer;
        padding: 0.5rem 1.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: $normal-transition;

        &[data-is-connected='false'] {
          background-color: #374151;
          &:hover {
            background-color: #f4f4f5;

            p {
              color: #000;
            }
          }
          p {
            font-weight: 500;
            font-size: 1.5rem;
            color: #fff;
            margin: 0 !important;
          }
          span {
            margin-left: 1rem;
            height: 1rem;
            width: 1rem;
            background-color: #ef4444;
            border-radius: 50%;
          }
        }

        &[data-is-connected='true'] {
          background-color: #22c55e;

          &:hover {
            background-color: #f4f4f5;

            p {
              color: #000;
            }

            span {
              background-color: #22c55e;
            }
          }
          p {
            font-weight: 500;
            font-size: 1.5rem;
            color: #fff;
            margin: 0 !important;
          }
          span {
            margin-left: 1rem;
            height: 1rem;
            width: 1rem;
            background-color: #f4f4f5;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__cards {
    display: flex;
    margin-bottom: 1.5rem;
    gap: 1rem;

    &--card {
      flex: 1;
      padding: 1.5rem;
      border-radius: 0.8rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
      transition: $normal-transition;
      cursor: pointer;
      // border: 1px solid transparent;
      border: 1px solid var(--main-border-color);

      &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      }

      &[data-is-card-selected='true'] {
        border: 1px solid var(--black-white-color);
      }

      &-icon {
        svg {
          width: 5rem;
          height: 5rem;
        }
      }

      &-text {
        p {
          font-size: 1.6rem;
          font-weight: 500;
          color: var(--main-text-color);
          margin: 0;
        }

        span {
          font-size: 1.4rem;
          color: #71717a;
        }
      }
    }
  }

  &__code {
    margin-top: 2rem;

    &--titles {
      p {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--main-text-color);
        margin: 0;
      }

      span {
        font-size: 1.35rem;
        color: var(--main-text-color);
      }

      ul {
        margin-left: 2rem;

        li {
          font-size: 1.35rem;
          color: var(--main-text-color);
          list-style-type: disc;

          strong {
            color: var(--main-text-color);
          }
        }
      }
    }

    &--code {
      margin-top: 1rem;
      overflow: hidden;
      max-height: 30rem;
      border-radius: 0.8rem;
      margin-bottom: 2rem;
      background-color: #2b2b2b;
      overflow-y: auto;

      -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
      scrollbar-width: none; /*Hide scrollbar for Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .css-1rd69im {
        top: 0.5rem !important;
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 0.5rem;

    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: rgb(22, 22, 22);
      &:hover {
        background-color: rgb(36, 36, 36);
      }
    }
  }

  &__button--light {
    width: 100%;
    margin-top: 2rem;

    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: transparent;
      border: 1px solid var(--main-text-color);
      color: var(--main-text-color);
    }
  }

  &__link {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--main-text-color);

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 0.5rem;
      transition: $normal-transition;
      cursor: pointer;
      &:hover {
        color: var(--main-text-color);
        text-decoration: underline;
      }
    }
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    img {
      width: 3rem;
      height: 3rem;
    }
  }
}
